import { Controller } from "@hotwired/stimulus"
import { string, bool } from 'yup'

export default class extends Controller {
  static targets = ['input', 'submit']

  initialize() {
    this.error = undefined
    this.dirty = []
  }

  connect() {
    this.inputTargets.forEach((input, inputIndex) => {
      input.addEventListener('input', () => this.onInput(input, inputIndex))
      input.addEventListener('blur', () => this.onInput(input, inputIndex))
    })

    this.submitTarget.closest('form').addEventListener('submit', (e) => this.onSubmit(e))
    this.submitTarget.addEventListener('click', (e) => this.onSubmit(e))

    this.dirty = this.inputTargets.map(() => false)

    this.checkIfHaveValue()
    this.disableSubmit()
  }

  async onInput(input, inputIndex) {
    this.error = undefined
    this.dirty[inputIndex] = true

    const form = input.closest('form')
    let rules = input.getAttribute('data-rules')

    if (rules.length) {
      try {
        rules = rules.split('|')
        for (let i in rules) {
          const [rule, params] = rules[i].split(':')

          switch (rule) {
            case "required":
              if (input.type === 'checkbox') {
                await bool().oneOf([true], "Este campo é obrigatório!")
                  .validate(input.checked)
              }
              else {
                await string()
                  .required('Este campo é obrigatório!')
                  .validate(input.value)
              }
              break
            case "email":
              await string()
                .email('Email mal formatado ')
                .validate(input.value)
              break
            case "min":
              await string()
                .min(params, `Campo deve ter no mínimo ${params}`)
                .validate(input.value)
              break
            case "confirm":
              const ref = params
              const refElement = form.querySelector(ref)

              await string()
                .oneOf([refElement.value, null], 'Senhas diferentes')
                .validate(input.value)
              break
          }

          this.hideErrorMessage(input)
        }
      } catch (e) {
        this.error = e.message
        this.showErrorMessage(input)
      }
    }

    this.disableSubmit()
  }

  checkIfHaveValue() {
    this.inputTargets.forEach((input, inputIndex) => {
      if (input.type === "checkbox") {
        this.dirty[inputIndex] = input.checked
      } else {
        this.dirty[inputIndex] = !!input.value
      }
    })
  }

  async onSubmit(event) {
    event.preventDefault()
    this.submitTarget.disabled = true
    this.submitTarget.classList.add('is-loading')
    this.submitTarget.classList.add('btn-disabled')
    setTimeout(() => this.submitTarget.closest('form').submit(), 500)
  }

  showErrorMessage(input) {
    let formControl = input.closest('.form-control')
    let helpElement = formControl.querySelector('.help')

    if (!helpElement) {
      helpElement = document.createElement('div')
      helpElement.classList.add('help')

      formControl.append(helpElement)
    }

    formControl.classList.add('has-error')
    helpElement.textContent = this.error
  }

  hideErrorMessage(input) {
    let formControl = input.closest('.form-control')
    let helpElement = formControl.querySelector('.help')

    if (helpElement) {
      helpElement.remove()
      formControl.classList.remove('has-error')
    }
  }

  disableSubmit() {
    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = !this.dirty.every((dirty) => dirty) || !!this.error
    }
  }
}
