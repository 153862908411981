import { Application } from "@hotwired/stimulus"

import MaskaController from "../controllers/maska_controller"
import TogglePasswordInputController from "../controllers/devise/utils/TogglePasswordInputController"
import AlertController from "../controllers/devise/utils/AlertController"
import FormController from "../controllers/devise/form/FormValidateController"

window.Stimulus = Application.start()

Stimulus.register("alert", AlertController)
Stimulus.register("form", FormController)
Stimulus.register("maska", MaskaController)
Stimulus.register("toggle-password", TogglePasswordInputController)
