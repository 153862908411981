import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'visible', 'hidden']

  initialize() {
    this.visible = false
    this.toggleTargets()
  }

  toggle() {
    this.visible = !this.visible
    
    this.toggleTargets()

    this.inputTarget.type = this.visible ? 'text' : 'password'
    this.inputTarget.focus()
  }

  toggleTargets() {
    this.visibleTarget.hidden = !this.visible
    this.hiddenTarget.hidden = this.visible
  }
}