import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['box', 'close']

  initialize() {
    this.visible = true
    this.applyTargetsMutations()
  }

  hide() {
    this.visible = false
    this.applyTargetsMutations()
  }

  applyTargetsMutations() {
    this.boxTarget.hidden = !this.visible
  }
}